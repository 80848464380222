import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout/layout';
import Quote from '../components/sections/quote';
import Request from '../components/sections/request';
import Awards from '../components/sections/awards';
import Services from '../components/sections/services';
import Body from '../components/sections/body';
import SEO from '../components/seo';

const AboutPage = () => {
  const pageData = useStaticQuery(graphql`
    {
      sanityServicesPage {
        name
        heading
        _rawBodySection
        bodySection {
          heading
        }
        image {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        serviceSection {
          heading
          subheading
          serviceList {
            name
            heading
            _rawDescription
            slug {
              current
            }
            pricingSection {
              heading
              subheading
              servicePriceList {
                cost
                duration
                durationType
                backgroundColor
              }
            }
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        reviewSection {
          heading
          subheading
          googleReviewsToggle
          reviewList {
            name
            location
            reviewText
            image {
              asset {
                fluid(maxWidth: 150) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        awardSection {
          awardList {
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        requestFormToggle
        quoteToggle
      }
    }
  `);

  const page = pageData.sanityServicesPage;

  return (
    <Layout showRequestButton heroHeading={page.heading} heroImage={page.image}>
      <SEO title="Services" />
      {/* Body Section */}
      <Body
        heading={page.bodySection.heading}
        content={page._rawBodySection.text}
      />
      {/* Services Section */}
      {page.serviceSection.serviceList.length > 0 && (
        <Services services={page.serviceSection} />
      )}

      {/* Request Form */}
      {page.requestFormToggle && (
        <Request services={page.serviceSection.serviceList} />
      )}
      {/* Awards */}
      <Awards awards={page.awardSection} />
      {/* Quote */}
      {page.quoteToggle && <Quote />}
    </Layout>
  );
};

export default AboutPage;
