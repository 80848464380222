import React from 'react';
import { Typography, Grid as MuiGrid } from '@material-ui/core';
import styled from 'styled-components';
import Section from './section';
import ServiceCard from '../cards/serviceCard';

const Grid = styled(MuiGrid)`
  padding: 1rem 0.5rem;
`;

const ServiceGrid = styled(MuiGrid)`
  padding-top: 1rem;
`;

const ServiceSection = styled(Section)`
  padding: 0 2rem 4rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 0 0 4rem 0;
  }
`;

const Services = ({ services }) => (
  <ServiceSection maxWidth="lg">
    <ServiceGrid container>
      <MuiGrid item component={Typography} variant="h2">
        {!services.heading ? 'Services' : services.heading}
      </MuiGrid>
      <MuiGrid item component={Typography} variant="h5">
        {services.subheading && services.subheading}
      </MuiGrid>
      <Grid item container warp="nowrap">
        {services.serviceList &&
          services.serviceList.map((service, index) => (
            <Grid item xs={12} sm={4} md={3} key={index}>
              <ServiceCard smallcard service={service} />
            </Grid>
          ))}
      </Grid>
    </ServiceGrid>
  </ServiceSection>
);
export default Services;
